// paper & background
$paper: #ffffff;

// primary
$blue50: #f6e7e7;
$blue200: #db9d9d;
$blue500: #b73a3a;
$blue600: #b13535;
$blue800: #a02727;

// secondary
$deepPurple50: #f6e7e7;
$deepPurple200: #db9d9d;
$deepPurple500: #b73a3a;
$deepPurple600: #b13535;
$deepPurple800: #a02727;

// success Colors
$A100: #b9f6ca;
$A200: #69f0ae;
$A400: #00e676;
$A700: #00c853;

// error
$red200: #ef9a9a;
$red500: #f44336;
$red800: #c62828;

// orange
$deepOrange50: #fbe9e7;
$deepOrange200: #ffab91;
$deepOrange800: #d84315;

// warning
$amber50: #fff8e1;
$amber100: #ffe57f;
$amber500: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

//-----------------------|| DARK THEME VARIANTS ||-----------------------//

// paper & background
$backgroundDark: #222222; // level 3
$paperDark: #000000; // level 4

// dark 800 & 900
$darkLevel1: #222222; // level 1
$darkLevel2: #252525; // level 2

// secondary dark
$APurple100: #fff;
$APurple200: rgb(190, 190, 190);
$APurple400: #4b4b4b;
$APurple700: #000000;

// text variants
$textDarkTitle: #d7dcec;
$textDarkPrimary: #fff;
$textDarkSecondary: #c48484;

//-----------------------|| JAVASCRIPT ||-----------------------//

:export {
    // paper & background
    paper: $paper;

    // primary
    blue50: $blue50;
    blue200: $blue200;
    blue500: $blue500;
    blue600: $blue600;
    blue800: $blue800;

    // secondary
    deepPurple50: $deepPurple50;
    deepPurple200: $deepPurple200;
    deepPurple500: $deepPurple500;
    deepPurple600: $deepPurple600;
    deepPurple800: $deepPurple800;

    // success
    A100: $A100;
    A200: $A200;
    A400: $A400;
    A700: $A700;

    // error
    red200: $red200;
    red500: $red500;
    red800: $red800;

    // orange
    deepOrange50: $deepOrange50;
    deepOrange200: $deepOrange200;
    deepOrange800: $deepOrange800;

    // warning
    amber50: $amber50;
    amber100: $amber100;
    amber500: $amber500;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    //-----------------------|| DARK THEME VARIANTS ||-----------------------//

    // paper & background
    paperDark: $paperDark;
    backgroundDark: $backgroundDark;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    textDarkTitle: $textDarkTitle;
    textDarkPrimary: $textDarkPrimary;
    textDarkSecondary: $textDarkSecondary;

    // secondary dark
    APurple100: $APurple100;
    APurple200: $APurple200;
    APurple400: $APurple400;
    APurple700: $APurple700;
}
